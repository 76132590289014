import React, { useEffect, useRef } from 'react';
import { string, bool } from 'prop-types';

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalFooter,
  Heading,
  Button,
  Text,
  Box,
  Image,
  Center,
  // hooks
  useDisclosure,
} from '@chakra-ui/react';

import { IMG_COVID } from '@/constants/assets';
import { DEFAULT_BUTTON_PROPS } from '@/constants/colors';
import txt from './locales';

/**
 * Function to render Covid Notification
 * @returns {JSX.Element | null}
 */
function CovidProtocol({ visible, lang }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isShowed = useRef(false);

  const handleCloseModal = () => {
    onClose();
  };

  useEffect(() => {
    if (visible && !isShowed.current) {
      setTimeout(() => {
        onOpen();
      }, 2000);
      isShowed.current = true;
    }
  }, []);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent width="90%" maxWidth="400px">
        <ModalBody fontSize="sm" padding="0">
          <Box textAlign="center" padding="8px 24px">
            <Heading color="alternativeDark" marginTop="16px" fontWeight="normal">
              {txt.title[lang]}
            </Heading>
            {false &&<Text fontSize="sm">{txt.subTitle[lang]}</Text>}
          </Box>
          <Center>
            <Image src={IMG_COVID[lang]} width="100%" />
          </Center>
          {false && (
            <Text
              padding="8px 24px"
              fontSize="xs"
              dangerouslySetInnerHTML={{ __html: txt.notes[lang] }}
            />
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            size="sm"
            color="mainColorTextLight"
            bgColor="bgAlternative"
            onClick={handleCloseModal}
            {...DEFAULT_BUTTON_PROPS}
          >
            {txt.understand[lang]}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

CovidProtocol.propTypes = {
  lang: string.isRequired,
  visible: bool.isRequired,
};

export default CovidProtocol;
